html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  width: 100%;
  min-width: 380px;
  height: 100%;
  overflow-x: hidden;
  background: var(--bg-default);
  font-family: Lato, Helvetica, Arial, sans-serif;

  input,
  textarea,
  button {
    color: var(--cap-typography-dark);
  }
}

#root {
  width: 100%;
  height: 100%;
}

.capsule {
  &.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    //https://github.com/truepill/react-capsule/pull/362
    &[data-size='sm'] {
      max-height: 48px;
      border-radius: var(--cap-btn-border-radius);
    }
  }

  [data-state='error'] input[type='number'] {
    padding: 0 2.75rem 0 0.75rem;
  }
}
